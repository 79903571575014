const defaultState={fecha:"",fechaOrigen:""};
function reducer(state= defaultState, action)
{
    switch(action.type){
        case 'actualizarFecha':{
            return {
            ...state,
            fecha: action.payload.fecha.fecha
            }
        }
        case 'actualizarFechaOrigen':{
            return {
            ...state,
            fechaOrigen: action.payload.fechaOrigen.fechaOrigen
            }
        }
        default:
            return state;
    }
}

export default reducer;