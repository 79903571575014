const defaultState={validacionCorrecto:0,validacionIncorrecto:0};
function reducer(state= defaultState, action)
{
    switch(action.type){
        case 'actualizarValidacionCorrecto':{
            return {
            ...state,
            validacionCorrecto: action.payload.validacionCorrecto
            }
        }
        case 'actualizarValidacionIncorrecto':{
            return {
            ...state,
            validacionIncorrecto: action.payload.validacionIncorrecto
            }
        }
        default:
            return state;
    }
}

export default reducer;