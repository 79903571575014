import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchArea from './SearchArea';
import GeneralService from '../apis/GeneralService';
import { Panel } from 'primereact/panel';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3C8DBC",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function ConsultaDeuda() {
    const classes = useStyles();
    const [showTable, setShowTable] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [toPay, setToPay] = React.useState(0);
    const searchAction = (credentialValue) => {
        setShowTable(false);
        GeneralService.getDebts(credentialValue).then(result=>{
            if(typeof result.data.status!=="undefined")
            {
                NotificationManager.error(result.data.status.msg, "", 5000, () => {});
            } 
            else{
            setData(result.data);
            let calculatedValue=0;
            result.data.forEach(record=>{
                calculatedValue=calculatedValue+Number.parseFloat(record.trcValorEmitido + record.trc_iva);
            });
            setToPay(calculatedValue);
            setShowTable(true);
        }
        },error=>{
            NotificationManager.error("Error en consulta por favor intente más tarde.", "", 5000, () => {});
        });
    }
    return (
        <React.Fragment>
            <Panel header={"Estado de Cuenta al: "+moment().format("YYYY-MM-DD")} style={{maxWidth: "100%" }}>
            <SearchArea searchAction={searchAction} />
            </Panel>
            <br />
            <br />
            <Panel header={"Valor Total: $"+toPay.toFixed(2).toString()} style={{maxWidth: "100%", fontSize:"25px"}}>
            {showTable &&
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                                <StyledTableCell align="left">NOMBRE</StyledTableCell>
                                <StyledTableCell align="left">Tributo</StyledTableCell>
                                <StyledTableCell align="left">Clave Catastral</StyledTableCell>
                                <StyledTableCell align="left">Fecha de Emisi&oacute;n</StyledTableCell>
                                <StyledTableCell align="left">Fecha de Vencimiento</StyledTableCell>
                                <StyledTableCell align="left">Valor</StyledTableCell>
                                <StyledTableCell align="left">Iva</StyledTableCell>
                                <StyledTableCell align="left">Estado</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <StyledTableRow key={row.hegClaveCatastral}>
                                    <StyledTableCell align="left">{row.proApellido} {row.proNombre}</StyledTableCell>
                                    <StyledTableCell align="left">{row.trbNombre}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hegClaveCatastral}</StyledTableCell>
                                    <StyledTableCell align="left">{moment(row.trcFechaEmision.replace("Z","")).format('YYYY-MM-DD')}</StyledTableCell>
                                    <StyledTableCell align="left">{moment(row.trcFechaVencimiento.replace("Z","")).format('YYYY-MM-DD')}</StyledTableCell>
                                    <StyledTableCell align="left">${row.trcValorEmitido}</StyledTableCell>
                                    <StyledTableCell align="left">${row.trc_iva}</StyledTableCell>
                                    <StyledTableCell align="left">{row.estado}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </Panel>
        </React.Fragment>
    );
}