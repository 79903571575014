import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GeneralService from '../../apis/GeneralService';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Link} from 'react-router-dom';

class BusquedaCedula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showInfo: false
        };
    }

    componentDidMount() {
        switch(this.props.type){
            case 1:{
                GeneralService.busquedaIcusCedula(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                    this.props.stopLoader();
                },
                error=>{
                    NotificationManager.error('Error...Por favor intente nuevamente..', "", 5000, () => {});
                    this.props.stopLoader();
                });
                break;
            }
            case 2:{
                GeneralService.busquedaIcusClaveCatastral(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                    this.props.stopLoader();
                },
                error=>{
                    NotificationManager.error('Error..Por favor intente nuevamente..', "", 5000, () => {});
                    this.props.stopLoader();
                });
                
                break;
            }
            default: break;

        }

    }
    getReport = (rowData) => {
        return  <Link to={`/icus/predio/${rowData.preId}/${rowData.sinat}`}>Ver Informe</Link>;
    }
    getCategory = (rowData) => {
        return  <div>{parseInt(rowData.sinat)===0?"URBANO":"RURAL"}</div>;
    }

    render() {
        return (
            <div>
                <br />
                {this.state.showInfo&&
                <div className="card">
                    <DataTable value={this.state.products}
                    selectionMode="single" dataKey="preId"
                    >
                        <Column header="Categoría" body={this.getCategory}></Column>
                        <Column field="identificacionPropietario" header="Cedula"></Column>
                        <Column field="codigoCatastral" header="Código Catastral"></Column>
                        <Column field="nombrePropietario" header="Propietario"></Column>
                        <Column field="preId" header="Predio"></Column>
                        <Column header="Informe" body={this.getReport}></Column>
                    </DataTable>
                </div>
            }
            </div>
        );
    }
}
export default BusquedaCedula;