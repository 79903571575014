import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';

class BaseLayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            layers: props.customparameters.fns.getBaseLayers()
        };

        this.onlayerChange = this.onlayerChange.bind(this);
    }

    onlayerChange(e) {
        let selectedlayers = [...this.state.layers];

        if (e.checked)
            selectedlayers.push(e.value);
        else
            selectedlayers.splice(selectedlayers.indexOf(e.value), 1);
       
        this.setState({ layers: selectedlayers },()=>{
            this.props.customparameters.fns.setBaseLayers(this.state.layers);
        });
    }
    render() {
        return (
            <div>
                <div className="card">
                    <h5>Capas Base</h5>
                    <div className="p-field-checkbox">
                        <Checkbox inputId="layer1" name="layer" value="globalGad:cat_manzana" onChange={this.onlayerChange} checked={this.state.layers.indexOf('globalGad:cat_manzana') !== -1} />
                        <label htmlFor="layer1">&nbsp;Manzanas</label>
                    </div>
                    <div className="p-field-checkbox">
                        <Checkbox inputId="layer2" name="layer" value="globalGad:cat_predio" onChange={this.onlayerChange} checked={this.state.layers.indexOf('globalGad:cat_predio') !== -1} />
                        <label htmlFor="layer2">&nbsp;Predio</label>
                    </div>
                    <div className="p-field-checkbox">
                        <Checkbox inputId="layer3" name="layer" value="globalGad:cat_piso_unidad_predio" onChange={this.onlayerChange} checked={this.state.layers.indexOf('globalGad:cat_piso_unidad_predio') !== -1} />
                        <label htmlFor="layer3">&nbsp;Piso</label>
                    </div>
                </div>
            </div>
        )
    }
}
export default BaseLayer;