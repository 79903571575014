import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import GeneralMapService from '../../apis/MapService';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import GeneralService from '../../apis/GeneralService';
import greySpinner from '../../../assets/images/greySpinner.gif';

class CovertLayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layers: props.customparameters.fns.getCovertLayers(),
            covertLayersCatalog: [],
            showInterface: false,
            nodes: null,
            checked: props.customparameters.fns.getCovertLayers(),
            expanded: [],
        };

        this.onlayerChange = this.onlayerChange.bind(this);
    }
    componentDidMount = () => {
        this.initLayer();
    }
    getRandomArbitrary = (min, max) => {
        return Math.random() * (max - min) + min;
    }

    initLayer = async () => {
        let covertTree = this.props.customparameters.fns.getCovertTree();
        if (covertTree.length === 0) {
            let wholeLayers=[];
            let nodes = [];
            GeneralService.getGeneralCategory().then(async result => {

                for (const categoryRow of result.data) {
                    if (typeof categoryRow.catId !== "undefined" && typeof categoryRow.catNombre !== "undefined") {
                        let children = [];
                        let node = {
                            value: `category${categoryRow.catId}id`,
                            label: categoryRow.catNombre,
                        };

                        const subCategoryResult = await GeneralService.getGeneralSubCategory(categoryRow.catId);
                        

                        for (const subCategoryRow of subCategoryResult.data) {
                            if (typeof subCategoryRow.catId !== "undefined") {
                                let subCategoryNode = {
                                    value: `subcategory${subCategoryRow.catId}id`,
                                    label: subCategoryRow.catNombre,
                                };
                                let subCategoryChildren = [];
                                try {
                                    const subCategoryLayer = await GeneralService.getGeneralSubCategoryLayer(subCategoryRow.catId);
                                    for (const subLayer of subCategoryLayer.data) {
                                        if (typeof subLayer.layer !== "undefined") {
                                            let layerNode = {
                                                value: subLayer.layer,
                                                label: subLayer.titulo
                                            };
                                            wholeLayers[subLayer.layer]=subLayer.titulo;
                                            subCategoryChildren.push(layerNode);
                                        }
                                    }
                                }
                                catch (error) {
                                    console.log(error);
                                }

                                subCategoryNode["children"] = subCategoryChildren;
                                children.push(subCategoryNode);
                            }

                        }
                        node["children"] = children;
                        nodes.push(node);
                        
                    }
                }


                if (nodes.length > 0) {
                    this.setState({ nodes }, () => {
                        this.props.customparameters.fns.setCovertTree(nodes);
                        this.props.customparameters.fns.setAssociativeCovert(wholeLayers);
                        this.setState({ showInterface: true });
                    });
                }


            });
        }
        else {
            this.setState({ nodes: covertTree }, () => {
                this.setState({ showInterface: true });
            });
        }




    }

    onlayerChange(e) {
        let selectedlayers = [...this.state.layers];

        if (e.checked)
            selectedlayers.push(e.value);
        else
            selectedlayers.splice(selectedlayers.indexOf(e.value), 1);

        this.setState({ layers: selectedlayers }, () => {
            this.props.customparameters.fns.setCovertLayers(this.state.layers);
        });
    }

    render() {
        return (
            <div>
                <div className="card">
                    {!this.state.showInterface&&
                    <>
                    <img src={greySpinner} width="40px" style={{display:"block",margin:"auto"}}/>
                    </>
                    }
                    {this.state.showInterface &&
                        <CheckboxTree
                            nodes={this.state.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={(checked) => { this.setState({ checked }); this.props.customparameters.fns.setCovertLayers(checked); }}
                            onExpand={expanded => this.setState({ expanded })}
                        />
                    }
                </div>
            </div>
        )
    }
}
export default CovertLayer;