export const INICIAR_IMPORACION = '[IMPORTAR] datos iniciales';
export const OBTENER_IMPORTACION = '[IMPORTAR] setear importacion';


function setearValoresInicial(datos) {
  return {
    type: INICIAR_IMPORACION,
    payload: datos,
  };
}
function setearImportacion(datos) {
  return {
    type: OBTENER_IMPORTACION,
    payload: datos,
  };
}
export const importarAcciones = {
  setearValoresInicial,
  setearImportacion,
};
