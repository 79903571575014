import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import GeneralService from '../apis/GeneralService';
import { Panel } from 'primereact/panel';
import Grid from '@material-ui/core/Grid';
import Map from './MapaImplantacion/Map';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import 'react-notifications/lib/notifications.css';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import { Column } from 'primereact/column';
import * as olProj from 'ol/proj'

class CompatibilidadSuelo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            showError: false,
            error: "",
            showTemplate: false,
            informe: null,
            params: null,
            showMap: false,
            showLocationImage: false,
            locationImage: null,
            showDetail: false,
            options: [],
            showOptions: false,
            selectedOption: {},
            actividad: "",
            activities: [],
            showActivities: false,
            currentPoint: [],
            showDetailReport: false,
            etiquetaActividad: "",
            detailReport: {},
            resultadoInforme: "",
            condiciones: [],
            selectedActivity: null,
            showPdfGeneration: true
        }
    }
    componentDidMount() {
        let params = this.props.match.params;
        GeneralService.ObtenerPredioIcus(params.predio, params.id).then(result => {
            let informe = result.data;
            this.setState({ informe, params }, () => {
                this.setState({ showTemplate: true, showMap: true, showLocationImage: false });
            });
        });
        GeneralService.ObtenerTipologia().then(result => {
            let options = [];
            result.data.tipologias.forEach(row => {
                let tmp = {
                    name: "[" + row.codigo + "]" + row.nombre,
                    code: row.codigo
                };
                options.push(tmp);
            });
            this.setState({ showOptions: true, options });
        });
    }
    registrarUbicacion = (coordenada) => {

        GeneralService.ObtenerUbicacionIcus(this.state.params.predio, this.state.params.id, coordenada[0], coordenada[1]).then(result => {
            let finalData = result.data;
            if (typeof finalData.ubicacionLote !== "undefined")
                this.setState({ locationImage: finalData.ubicacionLote.grafico, currentPoint: coordenada }, () => {
                    this.setState({ showMap: false, showLocationImage: true, showDetail: true });
                });
        });
    }
    optionChange = (e) => {
        this.setState({ selectedOption: e.value });
    }
    getTipology = (rowData) => {
        return <div><span style={{ color: "#40a9ff" }}>[{rowData.tipologia.codigo}] {rowData.tipologia.nombre}</span></div>;
    }
    getActivity = (rowData) => {
        return <div><span style={{ color: "#40a9ff" }}>[{rowData.codigo}] {rowData.nombre}</span></div>;
    }
    searchActivity = () => {

        this.setState({ showActivities: false }, () => {
            let typologyTmp = typeof this.state.selectedOption.code !== "undefined" ? this.state.selectedOption.code : "-1";
            if (this.state.actividad.length > 0)
                GeneralService.ObtenerActividad(this.state.actividad, typologyTmp).then(result => {
                    let activities = result.data.actividades;
                    this.setState({ activities, showActivities: true });
                });
        });
    }
    showActivityReport = (activityCode) => {

        this.setState({ showDetailReport: false, resultadoInforme: "", selectedActivity: activityCode }, () => {
            let xPoint = typeof this.state.currentPoint[0] !== "undefined" ? this.state.currentPoint[0] : 0;
            let yPoint = typeof this.state.currentPoint[1] !== "undefined" ? this.state.currentPoint[1] : 0;
            GeneralService.getActivityReport(this.state.params.predio, this.state.params.id, xPoint, yPoint, activityCode).then(result => {
                this.setState({ condiciones: typeof result.data.compatibilidadUso.condiciones !== "undefined" ? result.data.compatibilidadUso.condiciones : [], detailReport: result.data.compatibilidadUso, resultadoInforme: result.data.resultadoInforme }, () => {
                    let etiquetaActividad = "";
                    etiquetaActividad = (typeof this.state.detailReport.usoSuelo !== "undefined") ? "ACTIVIDAD ECONÓMICA - " + this.state.detailReport.usoSuelo.nombre : "";
                    this.setState({ showDetailReport: true, etiquetaActividad });
                });
            });
        });
    }
    downloadPdf = (pdf) => {
        let linkSource = 'data:application/octet-stream;base64,' + pdf;
        const downloadLink = document.createElement("a");
        const fileName = "VistaPrevia.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    vistaPrevia = () => {
        GeneralService.obtenerInformePreliminarIcus(this.state.params.predio, this.state.params.id, this.state.currentPoint[0], this.state.currentPoint[1]).then(resultado => {
            this.downloadPdf(resultado.data.icus.pdf.toString());
        });

    }
    generarInforme = () => {
        GeneralService.crearInformeIcus(this.state.params.predio, this.state.params.id, this.state.currentPoint[0], this.state.currentPoint[1]).then(resultado => {
            let numInforme = resultado.data.informe.numeroInforme;
            GeneralService.descargarInformeIcus(numInforme).then(resultadoPdf => {
                let pdfData = resultadoPdf.data;
                this.downloadPdf(pdfData.icus.pdf.toString());
            });

        });
    }
    generarVistaPreviaIcus = () => {
        GeneralService.generarInformeVistaPreviaIcus(this.state.params.predio, this.state.params.id, this.state.currentPoint[0], this.state.currentPoint[1], this.state.selectedActivity).then(resultado => {
            this.downloadPdf(resultado.data.icus.pdf.toString());
        });
    }
    generarInformeIcus = () => {
        GeneralService.generarInformeIcusProd(this.state.params.predio, this.state.params.id, this.state.currentPoint[0], this.state.currentPoint[1], this.state.selectedActivity).then(resultado => {
            GeneralService.verInformeIcusProd(resultado.data.informe.numeroInforme).then(
                res => {
                    this.downloadPdf(res.data.icus.pdf.toString());
                    this.setState({ showPdfGeneration: false });
                }, error => {

                }
            );
        });
    }
    render() {
        return (<React.Fragment>
            <div>
                <Panel header="INFORME DE COMPATIBILIDAD DE USO DE SUELO - LOTE EN UNIPROPIEDAD" style={{ maxWidth: "100%" }}>
                </Panel>
                {this.state.showTemplate &&
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Panel header={(!this.state.informe.sinat) ? "INFORMACIÓN PREDIAL EN UNIPROPIEDAD (URBANO)" : "INFORMACIÓN PREDIAL EN UNIPROPIEDAD (RURAL)"} style={{ textAlign: "center", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                <table border="1" width="100%" style={{ textAlign: "left" }}>
                                    <tr>
                                        <td colSpan={2}><b>DATOS DEL TITULAR DE DOMINIO</b></td>
                                    </tr>
                                    <tr>
                                        <td width="50%">C.C/R.U.C</td>
                                        <td>{this.state.informe.informacionCatastral.titularDominio.cedulaRuc}</td>
                                    </tr>
                                    <tr>
                                        <td>Nombre o raz&oacute;n social:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.titularDominio.nombreRazonSocial !== "undefined" ?
                                            this.state.informe.informacionCatastral.titularDominio.nombreRazonSocial : ""}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><b>DATOS DEL PREDIO</b></td>
                                    </tr>
                                    <tr>
                                        <td>Clave catastral:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.predio.claveCatastral !== "undefined" ?
                                            this.state.informe.informacionCatastral.predio.claveCatastral : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Clave catastral anterior:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.predio.claveCatastralAnterior !== "undefined" ?
                                            this.state.informe.informacionCatastral.predio.claveCatastralAnterior : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>En derechos y acciones:</td>
                                        <td>{this.state.informe.informacionCatastral.predio.derechosAcciones ? "SI" : "NO"}</td>
                                    </tr>
                                    <tr>
                                        <td>&Aacute;rea bruta total de construcci&oacute;n:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.predio.areaConstruccion !== "undefined" ?
                                            this.state.informe.informacionCatastral.predio.areaConstruccion : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>&Aacute;rea seg&uacute;n escritura:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.lote.areaEscritura !== "undefined" ?
                                            this.state.informe.informacionCatastral.lote.areaEscritura : ""} m2</td>
                                    </tr>
                                    <tr>
                                        <td>&Aacute;rea gr&aacute;fica:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.lote.areaGrafica !== "undefined" ?
                                            this.state.informe.informacionCatastral.lote.areaGrafica : ""} m2</td>
                                    </tr>
                                    <tr>
                                        <td>Frente:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.lote.frenteTotal !== "undefined" ?
                                            this.state.informe.informacionCatastral.lote.frenteTotal : ""} m</td>
                                    </tr>
                                    <tr>
                                        <td>Parroquia:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.lote.divisionPolitica !== "undefined" ?
                                            this.state.informe.informacionCatastral.lote.divisionPolitica.parroquia.nombre : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Barrio/Sector:</td>
                                        <td>{typeof this.state.informe.informacionCatastral.lote.divisionPolitica.barrio !== "undefined" ?
                                            this.state.informe.informacionCatastral.lote.divisionPolitica.barrio.nombre : ""}</td>
                                    </tr>
                                </table>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.state.showMap &&
                                <Map ubicacionLote={this.state.informe.informacionCatastral.ubicacionLote} registrarUbicacion={this.registrarUbicacion} />
                            }
                            {this.state.showLocationImage &&
                                <img src={"data:image/png;base64," + this.state.locationImage} height="100%" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />
                            }
                        </Grid>
                    </Grid>
                }
                {this.state.showDetail &&
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12}>
                            <Panel header={this.state.etiquetaActividad} style={{ textAlign: "center", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                <table border="0" style={{ textAlign: "left" }}>
                                    <tr>
                                        <td colspan="2">
                                            <button onClick={this.vistaPrevia}>Vista Previa</button>
                                            {/*<button onClick={this.generarInforme}>Generar Informe</button>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tipolog&iacute;a:</td><td> {this.state.showOptions && <Dropdown value={this.state.selectedOption} options={this.state.options} onChange={this.optionChange} optionLabel="name" placeholder="Buscar por:" />}</td>
                                    </tr>
                                    <tr>
                                        <td>Ingrese Actividad:</td><td> <InputText id="actividad" value={this.state.actividad} onChange={(e) => { this.setState({ actividad: e.target.value }); }} />
                                            <Tooltip title="Incluya información con tildes">
                                                <IconButton aria-label="buscar" onClick={this.searchActivity} >
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </table>
                                {this.state.showActivities &&
                                    <DataTable value={this.state.activities}
                                        onSelectionChange={(e) => {
                                            this.showActivityReport(e.value.codigo);
                                        }}
                                        selectionMode="single" dataKey="preId"
                                    >
                                        <Column header="Tipología" body={this.getTipology}></Column>
                                        <Column header="Actividad" body={this.getActivity}></Column>
                                    </DataTable>
                                }
                            </Panel>
                        </Grid>
                    </Grid>
                }
                {this.state.showDetailReport &&
                    <React.Fragment>
                        <table border="1" style={{ textAlign: "left" }}>
                            <tr>
                                <td colSpan={2}>
                                    <button onClick={this.generarVistaPreviaIcus}>Informe General - Vista Previa</button>
                                    {this.state.showPdfGeneration /*&& <button onClick={this.generarInformeIcus}>Informe General - Generar Informe</button>*/}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}><b>INFORME DE COMPATIBILIDAD</b> <b><span style={{ color: "orange" }}>{this.state.resultadoInforme}</span></b></td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                            </tr>
                            <tr>
                                <td width="50%">Actividad:</td>
                                <td>[{this.state.detailReport.actividad.codigo}] {this.state.detailReport.actividad.nombre}</td>
                            </tr>
                            <tr>
                                <td width="50%">Tipolog&iacute;a:</td>
                                <td>{this.state.detailReport.tipologia.codigo} {this.state.detailReport.tipologia.nombre}</td>
                            </tr>
                            <tr>
                                <td width="50%">Compatibilidad:</td>
                                <td>{this.state.detailReport.compatibilidad}</td>
                            </tr>
                            <tr>
                                <td width="50%">Cumple Condiciones de implantaci&oacute;n:</td>
                                <td>{this.state.detailReport.cumpleCondiciones ? "SI" : "NO"}</td>
                            </tr>
                        </table>

                        {(this.state.condiciones.length > 0) &&
                            <table border="1" style={{ textAlign: "left" }}>
                                <tr><td width="50%"><b>CONDICIONES DE IMPLANTACI&Oacute;N:</b></td></tr>

                                {this.state.condiciones.map(conditionRow =>
                                    <React.Fragment>
                                        <tr><td>
                                            {conditionRow.condicion}
                                            <br />
                                        </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </table>
                        }
                    </React.Fragment>
                }
            </div>
            {this.state.showError &&
                <div style={{ color: "red" }}>
                    <br />
                    {this.state.error}
                </div>
            }
        </React.Fragment>);
    }
}
export default CompatibilidadSuelo;