import React,{Component} from 'react';
import "./generalStyle.css";
import SearchPredio from './SearchPredio';
import Predio from './Finder/Predio';
import BusquedaCedula from './Finder/BusquedaCedula';
import BusquedaCatastral from './Finder/BusquedaCatastral';
import loader from '../../assets/images/loader2.gif';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

class Icus extends Component{
    constructor(props){
        super(props);
        this.state={
            userData:[],
            showInfo:false,
            id:null,
            option:"default",
            valor:null,
            renderInterface:false,
            showLoader:false,
            showCaptcha:true
        }
    }
    componentDidMount=()=>{
        loadCaptchaEnginge(6); 
    }
    doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;
  
        if (validateCaptcha(user_captcha)==true) {
            this.setState({showCaptcha:false});
            loadCaptchaEnginge(6); 
            document.getElementById('user_captcha_input').value = "";
        }
  
        else {
            alert('El código no es correcto. Intente nuevamente');
            document.getElementById('user_captcha_input').value = "";
        }
    };
    processPredio=(predio)=>{
        this.setState({showInfo:false,id:predio},()=>{
            this.setState({showInfo:true});
        });
    }
    searchAction=(valor,option)=>{
        this.setState({valor,option,renderInterface:false,showLoader:true},()=>{
            this.setState({renderInterface:true});
        }); 
    }
    stopLoader=()=>{
        this.setState({showLoader:false});
    }
    renderResult=(option)=>{
        switch(option){
            /*
            case "predio":{
                return <Predio id={this.state.valor} />
            break;
            }*/
            case "default":{
                return <div></div>
            break;
            }
            case "cedula":{
                return <BusquedaCedula id={this.state.valor} searchAction={this.searchAction} stopLoader={this.stopLoader} type={1}/>
                break;
            }
            case "claveCatastral":{
                return <BusquedaCedula id={this.state.valor} searchAction={this.searchAction} stopLoader={this.stopLoader} type={2}/>
                break;
            }
            default: break;
        }
    }
    render(){
        return(
        <React.Fragment>
        {this.state.showCaptcha&&
        <React.Fragment>
        <div className="container" style={{textAlign:"center"}}>
               <div className="form-group">
 
                   <div className="col mt-3">
                       <LoadCanvasTemplate />
                   </div>
 
                   <div className="col mt-3">
                       <div><input placeholder="Ingrese el valor del captcha" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                   </div>
 
                   <div className="col mt-3">
                       <div><button class="btn btn-primary" onClick={() => this.doSubmit()}>Enviar</button></div>
                   </div>
                     
               </div>
 
           </div>
        </React.Fragment>}
        {!this.state.showCaptcha&&<React.Fragment>
        <SearchPredio searchAction={this.searchAction}/> 
        {this.state.showLoader&&
        <React.Fragment><img src={loader} widh="55px"/> <span style={{color:"#40a9ff"}}>Espere un momento...</span></React.Fragment>
        }
        {this.state.renderInterface&&
        <div>
        {this.renderResult(this.state.option)}
        </div>
        } 
        </React.Fragment>}
        </React.Fragment>
        );
    }
}
export default Icus;