import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
//import CircleIcon from '@mui/icons-material/Circle';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import polygonIcon from "../images/polygon.svg";
import circleIcon from "../images/circle.svg";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function CustomZone(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <IconButton aria-label="Polígono" className={classes.margin} size="large" onClick={() => {
                props.customparameters.setPolygon();
            }}>
                <img src={polygonIcon} width="48px"/>
            </IconButton>
            Pol&iacute;gono
            <IconButton aria-label="Círculo" className={classes.margin} size="large" onClick={() => {
                props.customparameters.setPolygon("Circle");
            }}>
                <img src={circleIcon} width="48px"/>
            </IconButton>
            C&iacute;rculo
        </React.Fragment>
    );
}