import React, { Component, useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IndividualButton from './IndividualButton';
import logo from '../../assets/images/logo.png';
import RoomIcon from '@material-ui/icons/Room';
import LayersIcon from '@material-ui/icons/Layers';
import ShareIcon from '@material-ui/icons/Share';
import * as olProj from 'ol/proj';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import OlView from 'ol/View';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import HeightIcon from '@material-ui/icons/Height';
import GetAppIcon from '@material-ui/icons/GetApp';
import { OverviewMap, defaults as defaultControls } from 'ol/control';
import Select from 'ol/interaction/Select';
//import OSM from 'ol/source/OSM';
//import TileLayer from 'ol/layer/Tile';
import { Tile as TileLayer } from 'ol/layer';
import './css/overview.css';
import MapServices from './services/MapServices';
import OlMap from 'ol/Map';
import XYZ from 'ol/source/XYZ';
import { MapComponent } from '@terrestris/react-geo';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from 'ol/layer/Group';
import VisualizationMode from './modals/VisualizationMode';
import Download from './modals/Download';
import CustomZone from './modals/CustomZone';
import Measure from './modals/Measure';
import BaseLayer from './modals/BaseLayer';
import html2canvas from "html2canvas";
import CovertLayer from './modals/CovertLayer';
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Stroke, Circle, Fill, Icon as IconStyle } from 'ol/style';
import { OSM, Vector as VectorSource } from 'ol/source';
import Legend from "ol-ext/legend/Legend";
import LegendControl from "ol-ext/control/Legend";
import ruleIcon from "./images/rule.svg";
import worldIcon from "./images/world.svg";
import areaIcon from "./images/area.svg";
import baseLayerIcon from "./images/baseLayer.svg";
import cleanIcon from "./images/clean.svg";
import "ol-ext/dist/ol-ext.css";
import Projection from 'ol/proj/Projection';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import "rc-dock/dist/rc-dock.css";
import ImageWMS from 'ol/source/ImageWMS';
import AditionalInfo from './AditionalInfo';
import Info from './modals/Info';
import { DockLayout, DockContextType } from 'rc-dock';
import banner from "./images/banner.jpg";
import GeneralService from '../apis/GeneralService';
import "./css/floatingDiv.css";

let tab = {
  content: <div>Tab Content</div>,
  closable: true,
};
if (window.innerWidth < 600) {
}

const extent = [0, 0, 1024, 968];
const projection = new Projection({
  code: 'xkcd-image',
  units: 'pixels',
  extent: extent,
});


var emptyLegend = new Legend({
  items: [
  ]
});

class GeoPortal extends Component {
  constructor(props) {
    super(props);
    this.api_url = "";
    this.geoserver_url = "";
    fetch(`${process.env.PUBLIC_URL}/config.json`).then((r) => r.json())
        .then((initOptions) => {
            this.api_url = initOptions.api_url;
            this.geoserver_url = initOptions.geoserver_url;
        });
    this.state = {
      leftMenu: [],
      rightMenu: [],
      streetView: null,
      satelliteView: null,
      showMap: false,
      map: null,
      currentView: "street",
      center: olProj.fromLonLat([-78.171973,-2.455604]),
      baseLayers: [],
      highlightedLayer:null,
      covertLayers: [],
      overviewMapControl: null,
      covertTree: [],
      addLayer: true,
      showTabs: false,
      legendMapControl: new LegendControl({ legend: emptyLegend }),
      associativeCovert: [],
      predioLayer: false,
      layout: {
      },
      imagesToShow: [],
      legendUrls: [],
      mapInteraction: null,
      showAditionalInfo: false,
      aditionalInfoRecords: [],
      selectArea: new Select()
    };
  }
  setLegendUrls = (legendUrls) => {
    this.setState({ legendUrls });
  }
  addLegends = () => {
    MapServices.addLegends(this.state.baseLayers.concat(this.state.covertLayers), this.state.map, this.state.legendMapControl, this.setLegendMapControl, this.setLegendUrls)
  }
  setLegendMapControl = (mapControlLg) => {
    this.setState({ legendMapControl: mapControlLg });
  }
  setCovertTree = (covertTree) => {
    this.setState({ covertTree });
  }
  getCovertTree = () => {
    return this.state.covertTree;
  }
  setAssociativeCovert = (assocLayers) => {
    this.setState({ associativeCovert: assocLayers });
  }
  convertToLayerGroup = (records) => {
    let layers = [];
    records.children.forEach(row => {
      let individualLayer = new TileLayer({
        title: row,
        baseLayer: true,
        source: new TileWMS({
          url: `${this.geoserver_url}`,
          crossOrigin: 'anonymous',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.1',
            tiled: true,
            STYLES: '',
            LAYERS: row
          }
        })
      });
      layers.push(individualLayer);
    });

    let baseLayers = new LayerGroup(
      {
        name: records.name,
        title: records.tilte,
        displayInLayerSwitcher: true,
        openInLayerSwitcher: true,
        layers
      });
    return baseLayers;

  }
  removeLayer = (layerName) => {
    this.state.map.getLayers().getArray()
      .filter(layer => layer.get('name') === layerName)
      .forEach(layer => this.state.map.removeLayer(layer));
  };
  ensamblarImagenes = async () => {
    let input = window.document.getElementsByClassName("classMap")[0];
    const canvas = await html2canvas(input);
    var img = canvas.toDataURL("image/png");
    return img;
  }
  setBaseLayers = (baseLayers) => {
    this.removeLayer("capaBase");
    if (baseLayers.indexOf("globalGad:cat_predio") !== -1) this.setState({ predioLayer: true });
    else this.setState({ predioLayer: false });
    this.state.map.removeLayer(this.state.highlightedLayer);
    this.setState({ baseLayers });
    let newBaseLayer = {
      "name": "capaBase",
      "title": "Capa Base",
      "children": baseLayers
    };
    this.state.map.addLayer(this.convertToLayerGroup(newBaseLayer));
    this.setState({ showMap: false }, () => {
      this.setState({ showMap: true });
      this.addLegends();
    });

  }
  getBaseLayers = () => {
    return this.state.baseLayers;
  }

  getComplementImage = async (lyrName) => {
    const queryResult = await GeneralService.getLayerImages(lyrName);
    let toReturn = "";
    if (typeof queryResult["data"] !== undefined) {
      if (queryResult["data"][0] !== "undefined" && queryResult["data"].length > 0)
        toReturn = `https://portal-ciudadano.municipiodemejia.gob.ec/REGULACION/${queryResult["data"][0]}`;
    }
    return toReturn;
  }
  setCovertLayers = async (covertLayers) => {
    let imagesToShow = [];
    for (var i = 0; i < covertLayers.length; i++) {
      const layerName = this.state.associativeCovert[covertLayers[i]];
      const localImage = await this.getComplementImage(layerName);
      if (localImage.length > 0) imagesToShow[layerName] = localImage;
    }
    let layout = {
      dockbox: {
        mode: 'float',
        children: [
        ]
      },
      floatbox: {
        mode: 'float',
        children: [

        ]
      }
    };
    let it = 1;
    this.setState({ imagesToShow });
    for (const [key, value] of Object.entries(imagesToShow)) {
      layout.floatbox["children"].push(
        {
          tabs: [
            {
              ...tab, id: "id" + it.toString(), title: key, content:
                <div>
                  <img src={value} width="100%" />
                </div>
            }
          ],
          x: 0, y: 0, w: "100%", h: "100%"
        }
      );
      it++;
    }
    this.setState({
      layout
    }, () => {
      this.setState({ showTabs: false }, () => {
        this.setState({ showTabs: true });
      });
    });
    this.removeLayer("capaCovertura")
    this.setState({ covertLayers });
    let newCovertLayer = {
      "name": "capaCovertura",
      "title": "Capa Covertura",
      "children": covertLayers
    };
    this.state.map.addLayer(this.convertToLayerGroup(newCovertLayer));
    this.setState({ showMap: false }, () => {
      this.setState({ showMap: true });
      this.addLegends();
    })
  }
  getCovertLayers = () => {
    return this.state.covertLayers;
  }
  setPolygon = (parameter = 'Polygon') => {
    MapServices.setPolygon(this.state.map, parameter, this.setMapIteraction);
  }
  setMeasure = (parameter) => {
    if (this.state.addLayer) {
      this.setState({ addLayer: false });
      MapServices.setMeasure(this.state.map, parameter, true);
    } else
      MapServices.setMeasure(this.state.map, parameter, false);
  }
  setCircle = () => {
    MapServices.setPolygon(this.state.map, 'Circle');
  }
  changeMapView = (mode) => {
    if (this.state.currentView !== mode) {
      this.setState({ currentView: mode }, () => {
        switch (mode) {
          case "street": {
            this.removeLayer("satellite");
            this.addLayer("street");
            break;
          }
          case "satellite": {
            this.removeLayer("street");
            this.addLayer("satellite");
            break;
          }
          default: break;
        }
      });
    }
  };
  addLayer = (name) => {
    switch (name) {
      case "satellite": {
        this.state.map.addLayer(this.state.satelliteView);
        break;
      }
      case "street": {
        this.state.map.addLayer(this.state.streetView);
        break;
      }
      default: break;
    }
  }
  removeLayer = (layerName) => {
    this.state.map.getLayers().getArray()
      .filter(layer => layer.get('name') === layerName)
      .forEach(layer => {
        this.state.map.removeLayer(layer);
      });
  };
  clearSelection = () => {
    this.setState({
      leftMenu: [],
      rightMenu: [],
      streetView: null,
      satelliteView: null,
      showMap: false,
      map: null,
      baseLayers: [],
      covertLayers: [],
      overviewMapControl: null,
      covertTree: [],
      addLayer: true,
      showTabs: false,
      associativeCovert: [],
      predioLayer: false,
      layout: {
      },
      imagesToShow: [],
      legendUrls: [],
      mapInteraction: null,
      showAditionalInfo: false,
      aditionalInfoRecords: []
    }, () => {
      this.startComponent();
    });
  }
  setMapIteraction = (mapIteraction) => {
    this.setState({ mapIteraction });
  }
  startComponent = () => {

    const street = new OlLayerTile({
      name: "street",
      source: new OlSourceOsm()
    });
    const satellite = new TileLayer({
      name: "satellite",
      source: new XYZ({
        attributions: ['Powered by Esri', 'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
        attributionsCollapsible: false,
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'anonymous',
        maxZoom: 30
      })
    });

    const overviewMapControl = new OverviewMap({
      className: 'ol-overviewmap ol-custom-overviewmap',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      collapseLabel: '\u00BB',
      label: '\u00AB',
      collapsed: false,
    });

    this.setState({
      streetView: street,
      satelliteView: satellite
    });

    const mapView = new OlMap({
      view: new OlView({
        center: this.state.center,
        zoom: 16,
      }),
      layers: [street]
    });
    const raster = new TileLayer({
      source: new OSM(),
    });
    const source = new VectorSource();
    const vector = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });
    mapView.addLayer(raster);
    mapView.addLayer(vector);
    
    this.setState({ overviewMapControl }, () => {
      mapView.once('postrender', function (event) {
        mapView.addControl(overviewMapControl);
      });

    });
    var mapaUrlSource = new TileWMS({
      url: this.state.urlGenerico,
      params: {
        'FORMAT': 'image/png',
        'VERSION': '1.1.1',
        tiled: true,
        STYLES: '',
        serverType: 'geoserver',
        LAYERS: 'globalGad:cat_predio'
      }
    });
    var wmsLayer = new TileLayer({
      source: mapaUrlSource
    });
    let review = this;
    mapView.on('singleclick', async function (evt) {
      if (review.state.predioLayer) {
        review.setState({ showAditionalInfo: false }, async () => {
          var view = mapView.getView();
          var viewResolution = view.getResolution();
          var url = wmsLayer.getSource().getFeatureInfoUrl(
            evt.coordinate,
            viewResolution,
            view.getProjection(),
            { 'INFO_FORMAT': 'application/json'
          }
          );
          let urlResponse = await GeneralService.getDynamicalData(url);
          
          if (typeof urlResponse.data !== "undefined") {
            var polygon  = new Feature(
              {
                  geometry: new Polygon(
                  urlResponse.data.features[0].geometry.coordinates[0]
                  )
              }
          );
          var estiloCapaCreacion = new Style({
            fill: new Fill({
                color: 'rgba(199, 255, 51, 0.4)'
            }),
            stroke: new Stroke({
                color: 'red',
                width: 3
            })
        });
         var layer = new VectorLayer({ 
           source: new VectorSource({features: [polygon]}),
           style: estiloCapaCreacion
        });
         review.state.map.removeLayer(review.state.highlightedLayer);
         review.state.map.addLayer(layer);
         review.setState({
          highlightedLayer:layer
         });



            let aditionalInfoRecords = [];
            for (var ir = 0; ir < urlResponse.data.features.length; ir++) {
              let codigoCatastral = urlResponse.data.features[ir].properties.pre_codigo_catastral;
              let predioInfo = await GeneralService.getPredialInfo(codigoCatastral);
              
              aditionalInfoRecords.push(predioInfo.data);
            }
            review.setState({ aditionalInfoRecords }, () => {
              review.setState({ showAditionalInfo: true });
            });
          }
        });
      }

    });


    this.setState(
      {
        map: mapView
      }, () => {
        const resolution = this.state.map.getView().getResolution();
        const wmsSource = new ImageWMS({
          url: this.state.urlGenerico,
          params: { 'LAYERS': 'globalGad:limites_mejia' },
          ratio: 1,
          serverType: 'geoserver',
        });
        this.setState({ showMap: true });
      }
    );

    const leftMenu = [
      { value: "Selecci\u00F3n", type: "title" },
      {
        value: IndividualButton, type: "component", icon: ShareIcon, toolTip: "Área de Interés", toolTipPosition: "right-start", width: '250px',
        component: CustomZone, customparameters: { setPolygon: this.setPolygon, setCicle: this.setCircle }, svg: areaIcon
      },

      {
        value: IndividualButton, type: "customMethod", icon: ShareIcon, toolTip: "Limpiar Pantalla", toolTipPosition: "right-start", width: '450px',
        component: CustomZone, customFunction: this.clearSelection, svg: cleanIcon
      },
      { value: "Informaci\u00F3n", type: "title" },
      {
        value: IndividualButton, type: "component", icon: RoomIcon, toolTip: "Capas Base", toolTipPosition: "right-start", width: '450px',
        svg: baseLayerIcon,
        component: BaseLayer, customparameters: { fns: { setBaseLayers: this.setBaseLayers, getBaseLayers: this.getBaseLayers } }
      },
      {
        value: IndividualButton, type: "component", icon: LayersIcon, toolTip: "Indicadores", toolTipPosition: "right-start", width: '450px',
        component: CovertLayer, customparameters: {
          fns: {
            setAssociativeCovert: this.setAssociativeCovert, setCovertLayers: this.setCovertLayers, getCovertLayers: this.getCovertLayers,
            setCovertTree: this.setCovertTree, getCovertTree: this.getCovertTree
          }
        }
      },
      {
        value: IndividualButton, type: "component", icon: RecentActorsIcon, toolTip: "Información", toolTipPosition: "right-start", width: '500px',
        component: Info, customparameters: { fns: {} }
      },
    ];
    const rightMenu = [
      { value: "Visualizaci\u00F3n", type: "title" },
      {
        value: IndividualButton, type: "component", icon: "iconView", toolTip: "Estilo de Mapa", toolTipPosition: "left-start", width: '200px',
        component: VisualizationMode, customparameters: { changeMapView: this.changeMapView }, svg: worldIcon
      },
      { value: "Medidas", type: "title" },
      {
        value: IndividualButton, type: "component", icon: HeightIcon, toolTip: "Medidas", toolTipPosition: "left-start", width: '250px',
        component: Measure, customparameters: { setMeasure: this.setMeasure }, svg: ruleIcon
      },
      { value: "Exportar", type: "title" },
      {
        value: IndividualButton, type: "component", icon: GetAppIcon, toolTip: "Descargar", toolTipPosition: "left-start", width: '200px',
        component: Download, customparameters: {
          pdfDownload: () => {
            MapServices.exportPdf(this.state.map, this.state.overviewMapControl, this.state.imagesToShow, this.state.legendUrls, this.state.associativeCovert, this.state.baseLayers.concat(this.state.covertLayers));
          }
        }
      },
    ];

    this.setState({
      leftMenu,
      rightMenu
    });
  }
  componentDidMount() {
    this.startComponent();
  }

  closeAditionalInfo = () => {
    this.setState({ showAditionalInfo: false });
  }

  renderMenu = (row) => {
    if (row.type === "title") return <div clasName="geoPortalTitleBar" style={{ background: "#eec446", textAlign: "center" }}>{row.value}</div>;
    return <row.value icon={row.icon} toolTipTitle={row.toolTip} type={row.type}
      component={typeof row.component !== "undefined" ? row.component : null} customparameters={typeof row.customparameters !== "undefined" ? row.customparameters : row.customFunction}
      toolTipPosition={row.toolTipPosition} width={row.width} svg={typeof row.svg !== "undefined" ? row.svg : null} />;
  }
  render() {
    return (
      <React.Fragment>

        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} spacing={0}>
            <Box bgcolor="text.disabled" color="primary.contrastText" p={0}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} spacing={0}>
                  <div style={{width: "100%" }} onClick={() => { this.props.history.push("/"); }}>
                    <img src={banner} width="100%" />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} style={{ maxHeight: "95vh", minHeight: "95vh" }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={1} style={{ background: "#dad9d5" }}>
                <div className="geoPortalLeftBar">
                  {this.state.leftMenu.map(row => {
                    return this.renderMenu(row);
                  })
                  }
                </div>
              </Grid>
              <Grid item xs={12} sm={10} style={{ maxHeight: "95vh", minHeight: "95vh" }} className="classMap">
                {this.state.showMap &&
                  <MapComponent map={this.state.map} />
                }
              </Grid>
              <Grid item xs={12} sm={1} style={{ background: "#dad9d5" }}>
                <div className="geoPortalRightBar">
                  {this.state.rightMenu.map(row => {
                    return this.renderMenu(row);
                  })
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.showTabs &&
          <DockLayout defaultLayout={this.state.layout} style={{ position: 'absolute', left: "80%", top: "80%", right: 0, bottom: 0 }} />
        }
        {(this.state.showAditionalInfo && this.state.predioLayer) &&
          <div id="CenterDIV">
            <div className="divFloat">

              <p style={{ "textAlign": "left" }}>
                {
                  this.state.aditionalInfoRecords.map(value =>
                    <AditionalInfo valoresTabla={value} closeAditionalInfo={this.closeAditionalInfo} />
                  )
                }


              </p>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default GeoPortal;