import React, { Component } from 'react';
import GeneralService from '../../apis/GeneralService';
import Link from '@material-ui/core/Link';
class Info extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLinks: false,
            pdfNames: []
        };
    }
    preventDefault = (event) => event.preventDefault();
    componentDidMount() {
        GeneralService.getPdfGeneralInfo().then(result => {
            if (typeof result.data !== "undefined") {
                if (result.data.length > 0) {
                    console.log(result.data);
                    this.setState({
                        pdfNames: result.data
                    }, () => {
                        this.setState({ showLinks: true });
                    })
                }
            }
        });
    }
    render() {
        return (
            <div>
                <div className="card">
                    {
                        this.state.showLinks &&
                        <>
                            {this.state.pdfNames.map(row =>
                                <>
                                    <a href={`https://portal-ciudadano.municipiodemejia.gob.ec/REGULACION/${row}`} target="_blank">
                                        {row}
                                    </a>
                                    <br />
                                </>
                            )}
                        </>
                    }
                </div>
            </div>
        )
    }
}
export default Info;