import React from 'react';
import StreetviewIcon from '@material-ui/icons/Streetview';
import PublicIcon from '@material-ui/icons/Public';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import streetIcon from "../images/street.svg";
import worldIcon from "../images/world.svg";
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function VisualizationMode(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <IconButton aria-label="delete" className={classes.margin} size="large" onClick={() => {
                props.customparameters.changeMapView("street");
                props.changeIconState(1);
            }}>
                
                <img src={streetIcon} width="48px"/>
            </IconButton>
            Calles
            <IconButton aria-label="delete" className={classes.margin} size="large" onClick={() => {
                props.customparameters.changeMapView("satellite");
                props.changeIconState(2);
            }}>
                <img src={worldIcon} width="48px"/>
            </IconButton>
            Sat&eacute;lite
        </React.Fragment>
    );
}