import axios from 'axios';

class MapService {
  api_url = "";
  geoserver_url = "";
  
  constructor() {
    return (async () => {
      await this.initialize();
      return this; // when done
    })();
  }

  async initialize() {
    const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
    const initOptions = await response.json();
    this.api_url = initOptions.api_url;
    this.geoserver_url = initOptions.geoserver_url;
  }

  obtenerListaFuente = async () => {
    // Assure that api_url is not empty before making the request.
    if(this.api_url === ""){
      throw new Error("API URL is not initialized. Make sure to initialize the service before calling its methods.");
    }
    //return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaFuente`);
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaFuente`);
  }

}

export default new MapService();

