import {combineReducers} from "redux";
import module from "./module";
import menus from "./menus";
import breadcrumb from "./breadcrumb";
import importarReducer from "./importarReducer";
import fecha from "./fecha";
import tituloModal from "./tituloModal";
import generalJson from "./generalJson";
import validacion from "./validacion";
import keyCloak from "./keyCloak";

export default combineReducers({
    module,
    menus,
    breadcrumb,
    importarReducer,
    fecha,
    generalJson,
    tituloModal,
    validacion,
    keyCloak
});
