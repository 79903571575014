import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import GeneralService from '../../apis/GeneralService';
import { Panel } from 'primereact/panel';
import Grid from '@material-ui/core/Grid';

import 'react-notifications/lib/notifications.css';

class Predio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            showError: false,
            error: ""
        }
    }
    componentDidMount() {
        GeneralService.obtenerPredio(this.props.id,this.props.sinat).then(result => {
            this.setState({ userData: result.data}, () => {
                if (parseInt(result.data.status.codigo) === 0) {
                    NotificationManager.error('Error... '+result.data.status.msg, "", 5000, () => { });
                }
                else
                    this.setState({ showInfo: true });
            }
            );
        }
            ,
            error => {
                NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
            });
    }
    downloadPdf=(pdf)=>{
        let linkSource = 'data:application/octet-stream;base64,'+pdf;
        const downloadLink = document.createElement("a");
        const fileName = "VistaPrevia.pdf";
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    vistaPrevia=()=>{
        GeneralService.obtenerInformePreliminarIrm(this.props.id,0).then(resultado=>{
            let pdfData=resultado.data;
            this.downloadPdf(pdfData.irm.pdf.toString());
        });

    }
    generarInforme=()=>{
        GeneralService.crearInformeIrm(this.props.id,0).then(resultado=>{
            let numInforme=resultado.data.informe.numeroInforme;
            GeneralService.descargarInformeIrm(numInforme).then(resultado=>{
                let pdfData=resultado.data;
            this.downloadPdf(pdfData.irm.pdf.toString());
            });
            
        });
    }
    render() {
        return (
            <React.Fragment>
                {this.state.showInfo &&
                    <div>
                        <Panel header="INFORME PREDIAL DE REGULACIONES DEL USO DEL SUELO DEL CANTÓN MEJÍA" style={{ maxWidth: "100%" }}>

                        </Panel>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Panel header="INFORMACIÓN PREDIAL" style={{ textAlign: "center", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                    <table border="1" width="100%" style={{ textAlign: "left" }}>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL TITULAR DE DOMINIO</b></td>
                                        </tr>
                                        <tr>
                                            <td width="50%">C.C/R.U.C</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.cedulaRuc}</td>
                                        </tr>
                                        <tr>
                                            <td>Nombre o Raz&oacute;n Social</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.nombreRazonSocial}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL PREDIO</b></td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastral}</td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral Anterior</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastralAnterior}</td>
                                        </tr>
                                        <tr>
                                            <td>En derechos y acciones</td>
                                            <td>{(this.state.userData.informacionCatastral.predio.derechosAcciones) ? "SI" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <td>Propiedad Horizontal</td>
                                            <td>{(this.state.userData.informacionCatastral.predio.propiedadHorizontal) ? "SI" : "NO"}</td>
                                        </tr>
                                        {/*<tr>
                                            <td colSpan={2}><b>DATOS DEL LOTE</b></td>
                </tr>*/}
                                        <tr>
                                            <td>&Aacute;rea seg&uacute;n escritura:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaEscritura} m2</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea gr&aacute;fica</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaGrafica} m2</td>
                                        </tr>
                                        <tr>
                                            <td>Frente del Predio:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.frenteTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Tiene Construcci&oacute;n:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.construccion?"SI":"NO"}</td>
                                        </tr>
                                        <tr>
                                            <td>Parroquia:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.divisionPolitica.parroquia.nombre}</td>
                                        </tr>
                                        <tr>
                                            <td>Barrio/Sector:</td>
                                            <td>{
                                            typeof this.state.userData.informacionCatastral.lote.divisionPolitica.barrio!=="undefined"?
                                            this.state.userData.informacionCatastral.lote.divisionPolitica.barrio.nombre:""
                                            }</td>
                                        </tr>
                                    </table>
                                </Panel>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Panel header={"IMPLANTACIÓN GRÁFICA DEL LOTE"} style={{ maxWidth: "100%", display: "block", marginLeft: "auto !important", marginRight: "auto !important" }}>

                                    <img src={"data:image/png;base64," + this.state.userData.informacionCatastral.ubicacionLote.grafico} width="50%" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />
                                    <br />
                                    <table border="0" width="100%">
                                    <tr>
                                        <td colspan="2" align='center'>
                                        <button onClick={this.vistaPrevia}>Vista Previa</button> 
                                        {/*<button onClick={this.generarInforme}>Generar Informe</button>*/}
                                        </td>
                                    </tr>
                                    </table>

                                </Panel>
                            </Grid>
                        </Grid>
                        {(typeof this.state.userData.vias!=="undefined")&&
                        <Panel header="VIAS" style={{ maxWidth: "100%" }}>
                            <table border="0" width="100%">
                            <tr>
                                <td>
                                <b>Tipo</b>
                                </td>
                                <td>
                                <b>Nombre:</b>
                                </td>
                                {/*<td>
                                <b>Ancho (m):</b>
                                </td>
                                <td>
                                <b>Acera (m):</b>
                                </td>
                                <td>
                                <b>Calzada:</b>
                                        </td>*/}
                                </tr>
                                {this.state.userData.vias.map(rowAfectacion=>
                                <tr>
                                <td>
                                {rowAfectacion.tipo}
                                </td>
                                <td>
                                {rowAfectacion.nombre}
                                </td>
                                {/*<td>
                                {rowAfectacion.ancho}
                                </td>
                                <td>
                                {rowAfectacion.acera}
                                </td>
                                <td>
                                {rowAfectacion.ancho-2*rowAfectacion.acera}
                                </td>*/}
                                </tr>
                                )
                                }
                            </table>
                        </Panel>
                        }
                        <Panel header="REGULACIONES" style={{ maxWidth: "100%" }}>
                            <b>ZONIFICACI&Oacute;N</b>
                            <Grid container spacing={0}>
                                <Grid item xs={8}>
                                    {this.state.userData.regulaciones.map(regulacion=>
                                    <div>
                                   <b></b>
                                    <br />
                                    Zona: {regulacion.zona}
                                    <br />
                                    Lote m&iacute;nimo: {regulacion.loteMinimo}
                                    <br />
                                    Frente m&iacute;nimo: {regulacion.frenteMinimo}
                                    <br />
                                    COS PB: {regulacion.cosPb} %
                                    <br />
                                    COS TOTAL: {regulacion.cosTotal}%
                                    <br />
                                    Forma de ocupaci&oacute;n de suelo: {regulacion.formaOcupacion.nombre}
                                    <br />
                                    <b>CLASIFICACI&Oacute;N DE SUELO:</b> ({regulacion.clasificacionSuelo.codigo}) {regulacion.clasificacionSuelo.nombre}
                                    <br />
                                    <b>USO DE SUELO:</b> ({regulacion.usoSuelo.codigo}) {regulacion.usoSuelo.nombre}
                                    <br />
                                    <b>SUB CLASIFICACI&Oacute;N DE SUELO:</b> ({regulacion.subClasificacionSuelo.codigo}) {regulacion.subClasificacionSuelo.nombre}
                                    <br />
                                    <b>TRATAMIENTO:</b> ({regulacion.tratamiento.codigo}) {regulacion.tratamiento.nombre}
                                    <br />
                                    <b>USO DE SUELO ESPECIFICO:</b> ({regulacion.usoSueloEspecifico.codigo}) {regulacion.usoSueloEspecifico.nombre}
                                    <br />
                                    <b>({regulacion.pit.nombre})</b> 
                                    <br />
                                    <b>USO PRINCIPAL</b>
                                    <br />
                                    ({regulacion.pit.usoPrincipal})
                                    <br />
                                    <b>USOS COMPLEMENTARIOS</b>
                                    <br />
                                    ({regulacion.pit.complementario})
                                    <br />
                                    <b>USOS RESTRINGIDOS</b>
                                    <br />
                                    ({regulacion.pit.restringido})
                                    <br />
                                    <b>USOS PROHIBIDOS</b>
                                    <br />
                                    ({regulacion.pit.prohibido})
                                    </div>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <b>PISOS</b>
                                    {this.state.userData.regulaciones.map(regulacion=>
                                    <div>
                                    <br />
                                    Altura: {regulacion.alturaPisos}m
                                    <br />
                                    N&uacute;mero de pisos: {regulacion.numeroPisos}
                                    </div>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <b>RETIROS</b>
                                    {this.state.userData.regulaciones.map(regulacion=>
                                    <div>
                                    <br />
                                    Frontal: {regulacion.retiroFrontal} m
                                    <br />
                                    Lateral 1: {regulacion.retiroLateral1} m
                                    <br />
                                    Lateral 2: {regulacion.retiroLateral2} m
                                    <br />
                                    Posterior: {regulacion.retiroPosterior} m
                                    <br />
                                    Entre bloques: {regulacion.distanciaEntreBloques} m
                                    </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Panel>
                        <Panel header="COBERTURA DE SERVICIOS BÁSICOS" style={{ maxWidth: "100%" }}>
                        <Grid container spacing={0}>
                                <Grid item xs={6}>
                                <b>ABASTECIMIENTO DE AGUA:</b> {this.state.userData.servicios.agua}
                                <br />
                                <b>ENERG&Iacute;A EL&Eacute;CTRICA:</b> {this.state.userData.servicios.electricidad}
                                </Grid>
                                <Grid item xs={6}>
                                <b>ALCANTARILLADO:</b> {this.state.userData.servicios.alcantarillado}
                                <br />
                                <b>OTROS:</b> {this.state.userData.servicios.otros}
                                </Grid>
                        </Grid>
                        </Panel>
                        {(typeof this.state.userData.afectacionesVias!=="undefined")&&
                        <Panel header="AFECTACIÓN VIAL" style={{ maxWidth: "100%" }}>
                            <table border="0" width="100%">
                            <tr>
                                <td>
                                <b>Tipo</b>
                                </td>
                                <td>
                                <b>Nombre:</b>
                                </td>
                                <td>
                                <b>Ancho Total:</b>
                                </td>
                                <td>
                                <b>Ret (m):</b>
                                </td>
                                <td>
                                <b>Observaci&oacute;n:</b>
                                </td>
                                </tr>
                                {this.state.userData.afectacionesVias.map(rowAfectacion=>
                                <tr>
                                <td>
                                {rowAfectacion.tipo}
                                </td>
                                <td>
                                {rowAfectacion.nombre}
                                </td>
                                <td>
                                {rowAfectacion.ancho}
                                </td>
                                <td>
                                {rowAfectacion.retiro}
                                </td>
                                <td>
                                {rowAfectacion.observacion}
                                </td>                               
                                </tr>
                                )
                                }
                            </table>
                        </Panel>
                        }
                        {(typeof this.state.userData.protecciones!=="undefined")&&
                        <Panel header="PROTECCIONES" style={{ maxWidth: "100%" }}>
                            <table border="0" width="100%">
                            <tr>
                                <td>
                                <b>Tipo</b>
                                </td>
                                <td>
                                <b>Nombre:</b>
                                </td>
                                <td>
                                <b>Observaci&oacute;n:</b>
                                </td>
                                </tr>
                                {this.state.userData.protecciones.map(rowAfectacion=>
                                <tr>
                                <td>
                                {rowAfectacion.tipo}
                                </td>
                                <td>
                                {rowAfectacion.nombre}
                                </td>
                                <td>
                                {rowAfectacion.observacion}
                                </td>             
                                </tr>
                                )
                                }
                            </table>
                        </Panel>
                        }
                        {(typeof this.state.userData.notasAdicionales!=="undefined")&&
                        <Panel header="LISTA DE NOTAS" style={{ maxWidth: "100%" }}>
                            <table border="1" width="100%">
                                {this.state.userData.notasAdicionales.map(nota=>
                                <tr>
                                <td>
                                {nota}
                                </td>
                                </tr>
                                )
                                }
                            </table>
                        </Panel>
                        }
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                }
                {this.state.showError &&
                    <div style={{ color: "red" }}>
                        <br />
                        {this.state.error}
                    </div>}
            </React.Fragment>
        );
    }
}
export default Predio;